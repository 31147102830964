/*
*
|  Page Name       : aboutus.scss
|  Description     : scss for civil-infrastructure us page use
|  Created by      : May Altamerano
|  Date Created    : June 29 2021
|  Last Update by  : May Altamerano
|  Last update     : July 1 2021
*
*/

.--building {
  .svg {
    position: absolute;
    pointer-events: none;

    &--1 {
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      mix-blend-mode: multiply;
    }
  }

  .--header-building {
    position: relative;
    width: 100%;
    margin-top: 14vw;
  }
  .--right {
    position: absolute;
    right: 0;
    z-index: 9;
    top: -21vw;
    width: 30vw;
  }

  .--left {
    position: absolute;
    left: 0;
  }

  .hr-carousel {
    width: 25vw;
    opacity: 1;
  }

  .carousel {
    // width: 100vw;
    margin: 0 auto;
  }

  .civilinfra {
    padding-top: 5vw !important;
  }

  .--right-1 {
    position: absolute;
    top: 110vw;
  }

  .--right-2 {
    position: absolute;
    top: 47vw;
  }

  .--right-3 {
    position: absolute;
    top: 7vw;
  }

  .--right-4 {
    position: absolute;
    right: 0;
    top: 138vw;
  }

  .--right-5 {
    position: absolute;
    right: 0;
    top: 85vw;
  }

  .--right-6 {
    position: absolute;
    right: 0;
    top: -13.7vw;
  }

  .--bg-mid {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100vw;
    top: 0;
  }
  width: 100%;
  position: relative;

  .carousel-caption {
    padding-top: 0vw;
    position: absolute;
    top: 15vw;
    left: 54vw;
    color: #283a97;
    letter-spacing: 0.2vw;
  }

  .--header-container {
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3vw;
      font-size: 2.3vw;
      color: #283a97;
      margin: 2.4vw 0 0 0;
      text-align: center;
    }

    .--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 400 !important;
      margin-bottom: 0;
      font-size: 1.5vw;
      letter-spacing: 0.19vw;
      color: #283a96;
      line-height: 1.8;
      margin-top: 2.8vw;
      text-align: center;
    }

    .--build-project-list {
      margin-top: 2vw;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      max-width: 95%;

      figure {
        flex: 1;

        img {
          margin: 0 auto 0 0;
          display: block;
          height: 50px;
          width: 50px;
          object-fit: contain;
          object-position: center;
          image-rendering: -webkit-optimize-contrast;
        }

        figcaption {
          span {
            font-family: "gill sans", sans-serif;
            font-weight: 400 !important;
            margin-bottom: 0;
            font-size: 0.59vw;
            color: #283a97;
            text-transform: uppercase;
            line-height: 1.6;
            display: block;
            width: 66px;
            margin: 0.4vw auto 0 0;
          }
        }
      }
    }

    .--max {
      max-width: 75%;
    }

    .--about-content {
      height: 50vw;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 15vw;
    }

    .splide__arrows {
      display: none;
    }

    .splide__pagination {
      position: absolute;
      left: 27.7vw;
      right: unset;
      flex-direction: row;
      width: auto;
      justify-content: flex-end;
      bottom: 9vw;

      li {
        height: 20px !important;
        width: 20px !important;
        margin-right: 50px;
        margin-bottom: 0 !important;
      }

      .splide__pagination__page {
        width: 20px !important;
        height: 20px !important;
        border: 2px solid #283a97 !important;
        border-radius: 100px !important;
        transform: none;

        &.is-active {
          background-color: #283a97 !important;
        }
      }
    }
  }

  .--container-4 {
    position: relative;
    padding-top: 50vw;
    height: 100%;
  }

  .--container-2 {
    position: relative;
    padding: 0vw 0;
    margin-bottom: -2%;

    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }

    .--btn-view-project1 {
      position: relative;
      // top: 20vh;
      background: white;
      text-decoration: none;
      padding-left: 2vw;
      padding-right: 2vw;
      padding-top: 1vh;
      padding-bottom: 1vh;
      color: #283a97;
      border: 1px solid gray;
      font-family: "gill sans";
      text-transform: uppercase;
      letter-spacing: 0.2vw;
    }

    .--btn-view-project {
      position: relative;
      // top: 20vh;
      background: white;
      text-decoration: none;
      padding-left: 2vw;
      padding-right: 2vw;
      padding-top: 1vh;
      padding-bottom: 1vh;
      color: #283a97;
      border: 1px solid gray;
      font-family: "gill sans";
      text-transform: uppercase;
      letter-spacing: 0.2vw;
    }

    .--btn-view-project1:hover {
      background: #283a97;
      color: white;
    }
    .--btn-view-project:hover {
      background-color: #283a97 !important;
      color: white;
    }

    .splide__arrows {
      display: none;
    }

    .splide__list {
      width: 100%;
    }

    .splide {
      &--1,
      &--2 {
        .splide__pagination {
          top: 43vw;
        }
      }
    }

    .splide__slide {
      width: 100% !important;
      height: auto !important;
      display: flex;

      img {
        width: 100% !important;
        // height: 100% !important;
      }
    }

    // position: relative;
    .splide__pagination {
      left: 67.5vw;
      right: unset;
      flex-direction: row;
      width: auto;
      justify-content: flex-end;
      top: 36vw;
      height: min-content;

      li {
        height: 20px !important;
        width: 20px !important;
        margin-right: 50px;
        margin-bottom: 0 !important;
      }

      /* & > *:nth-child(1) {
        opacity: 0;
      } */

      .splide__pagination__page {
        width: 30px !important;
        height: 30px !important;
        border: 2px solid #283a97 !important;
        border-radius: 100px !important;
        transform: none;
        background: transparent;

        &.is-active {
          background-color: #283a97 !important;
        }
      }
    }

    .--right-container {
      top: 28vh;
      position: absolute;
      right: 12.5vw;
      width: 27%;
      color: #283a97;
      font-family: "gill sans";
      letter-spacing: 0.15em;

      .thin {
        font-family: "gill sans medium", sans-serif !important;
      }

      hr {
        height: 1px;
        background-color: #283a97;
        width: 100%;
        margin-bottom: 1vw;
        margin-top: 1vw;
        right: 0vw;
        opacity: 1;
      }

      h5 {
        &.--title {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.3vw;
          font-weight: 500 !important;
          text-transform: uppercase;
          line-height: 2.5vw;
          font-size: 1.2vw;
          color: #283a97;
          margin: 0;
          text-align: left;
          margin-left: auto;
          margin-bottom: 0.8vw;
        }
      }

      p {
        &.--desc {
          font-family: "gill sans light", sans-serif !important;
          letter-spacing: 0.2vw;
          font-weight: 400 !important;
          text-transform: uppercase;
          line-height: 2.3vw;
          font-size: 1.1vw;
          color: #ffffff;
          margin: 2.5vw 0 0 0;
          text-align: center;
          width: 95%;
          margin-left: auto;
        }
      }
    }

    .--centerimg {
      width: 50vw;
    }

    .col {
      text-align: center;

      hr {
        width: 20vw;
        opacity: 1;
        color: #283a97;
        margin: 0 auto;
      }
    }

    h1 {
      &.--title {
        font-family: "gill sans light", sans-serif !important;
        letter-spacing: 0.5vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 2.8vw;
        color: #283a97;
        margin: 4vw 0 0 0;
        text-align: center;
      }
    }

    h2 {
      &.--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.4vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 2.2vw;
        color: #283a97;
        margin: 5.5vw 0 0 0;
        text-align: center;
      }
    }

    p {
      &.--sub-title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.2vw;
        font-weight: 600 !important;
        line-height: 1.9vw;
        font-size: 1.2vw;
        color: #676ab1;
        margin: 5.5vw 0 0 0;
        text-align: center;
      }
    }
  }

  .--container-3 {
    // background: url("../images/aboutus/About_BG-03.png") top center;
    height: 64vw;
    // background-repeat: no-repeat;
    // background-size: 118vw 70vw;
    // transition: background 0.3s;
    // background-attachment: scroll;
    padding: 10vw 0;
    position: relative;

    img {
      &.--img {
        position: absolute;
        height: 15vw;
        width: 15vw;
        image-rendering: -webkit-optimize-contrast;
        object-fit: contain;
        object-position: 0 0;
        top: 17vw;
        left: 14vw;
      }
    }

    h2 {
      &.--title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.4vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 3vw;
        font-size: 2.2vw;
        color: #ffffff;
        margin: 14vw 0 0 0;
        text-align: center;
      }
    }

    p {
      &.--sub-title {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.5vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.7;
        font-size: 2vw;
        color: #ffffff;
        margin: 5vw 0 0 0;
        text-align: center;
      }
    }
  }

  .--container-5 {
    height: 50vw;
    padding: 0;
    position: relative;

    .--max {
      max-width: 75%;
    }

    .--bg-1 {
      background: url("../images/aboutus/About_BG-07.png") top left -3vw;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 76vw 55vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 100vw;
      width: 100%;
      position: absolute;
      top: -17vw;

      &::before {
        content: "";
        background-color: #283997;
        position: absolute;
        top: 19vw;
        left: 0;
        width: 100%;
        height: 50vw;
      }
    }

    .--bg-2 {
      background: url("../images/aboutus/About_BG-07.png") top left -4vw;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 105vw 55vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 100vw;
      width: 100%;
      position: absolute;
      bottom: -4vw;
      transform: scale(-1, -1);
    }

    .splide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 50vw;
      // width: 1140px;
      margin: 0 auto;

      .splide__track {
        position: relative;
        top: -13.5vw;
        width: 100%;

        ul,
        li:nth-child(2) {
          margin-bottom: 10vh !important;
        }
        img {
          // width: 66%;
          width: 65vw;
          height: 40vw;
          object-fit: cover;
          object-position: center;

          @media not all and (min-resolution: 0.001dpcm) {
            width: 50vw;
          }
        }

        .splide__list {
          width: 100%;
          color: red;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .splide__slide {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
          }
        }
      }
    }

    .splide__arrows {
      display: none;
    }

    .splide__pagination {
      position: absolute !important;
      bottom: 10vw;
      left: unset;
      transform: unset;

      @media not all and (min-resolution: 0.001dpcm) {
        bottom: 7vw !important;
      }

      li {
        margin: 0 1.1vw;
      }

      .splide__pagination__page {
        height: 25px;
        width: 25px;
        background-color: transparent !important;
        border: 1px solid #ffffff !important;

        &.is-active {
          transform: none !important;
          background-color: #ffffff !important;
        }
      }
    }

    .--right-container {
      top: 4vw;
      position: relative;
      left: 0;
      width: 44%;

      hr {
        height: 3px;
        background-color: #3472a9;
        width: 68vh;
        right: 0vw;
        position: absolute;
      }

      h5 {
        &.--title {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.3vw;
          font-weight: 500 !important;
          text-transform: uppercase;
          line-height: 2.5vw;
          font-size: 1.2vw;
          color: #ffffff;
          margin: 0;
          text-align: center;
          width: 95%;
          margin-left: auto;
          margin-bottom: 0.8vw;
        }
      }

      p {
        &.--desc {
          font-family: "gill sans light", sans-serif !important;
          letter-spacing: 0.2vw;
          font-weight: 400 !important;
          text-transform: uppercase;
          line-height: 2.3vw;
          font-size: 1.2vw;
          color: #ffffff;
          margin: 2.5vw 0 0 0;
          text-align: center;
          width: 95%;
          margin-left: auto;
        }
      }
    }
  }

  .--container-6 {
    height: 50vw;
    padding: 10vw 0;
    position: relative;
  }

  .--container-7 {
    height: 50vw;
    padding: 0;
    position: relative;

    .--max {
      max-width: 73%;
    }

    .--bg-1 {
      background: url("../images/aboutus/About_BG-07.png") top left -2vw;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 60vw 32vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 50vw;
      width: 100%;
      position: absolute;
      top: -10vw;

      &::before {
        content: "";
        background-color: #283997;
        position: absolute;
        top: 10vw;
        left: 0;
        width: 100%;
        height: 50vw;
      }
    }

    .--bg-2 {
      background: url("../images/aboutus/About_BG-07.png") top left;
      image-rendering: -webkit-optimize-contrast;
      background-repeat: no-repeat;
      background-size: 65vw 55vw;
      transition: background 0.3s;
      background-attachment: scroll;
      height: 100vw;
      width: 100%;
      position: absolute;
      bottom: -14vw;
      transform: scale(-1, -1);
    }

    .splide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 50vw;
      // width: 1140px;
      margin: 0 auto;

      .splide__track {
        position: relative;
        top: -16vw;
        width: 100%;

        img {
          // width: 66%;
          width: 65vw;
          height: 40vw;
          object-fit: cover;
          object-position: center;

          @media not all and (min-resolution: 0.001dpcm) {
            width: 50vw;
          }
        }

        .splide__list {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          .splide__slide {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
          }
        }
      }
    }

    .splide__arrows {
      display: none;
    }

    .splide__pagination {
      position: absolute !important;
      bottom: 14vw;
      left: unset;
      transform: unset;

      li {
        margin: 0 1vw;
      }

      .splide__pagination__page {
        height: 23px;
        width: 23px;
        background-color: transparent !important;
        border: 1px solid #ffffff !important;

        &.is-active {
          transform: none !important;
          background-color: #ffffff !important;
        }
      }
    }

    .--right-container {
      top: 5vw;
      position: relative;
      left: 0;
      width: 44%;

      hr {
        height: 3px;
        background-color: #676ab0;
        width: 68vh;
        right: 0vw;
        position: absolute;
      }

      h5 {
        &.--title {
          font-family: "gill sans", sans-serif !important;
          letter-spacing: 0.3vw;
          font-weight: 500 !important;
          text-transform: uppercase;
          line-height: 2.5vw;
          font-size: 1.2vw;
          color: #ffffff;
          margin: 0;
          text-align: center;
          width: 95%;
          margin-left: auto;
          margin-bottom: 0.8vw;
        }
      }

      p {
        &.--desc {
          font-family: "gill sans light", sans-serif !important;
          letter-spacing: 0.2vw;
          font-weight: 400 !important;
          text-transform: uppercase;
          line-height: 2.3vw;
          font-size: 1.1vw;
          color: #ffffff;
          margin: 2.5vw 0 0 0;
          text-align: center;
          width: 95%;
          margin-left: auto;
        }
      }
    }
  }

  .--container-8 {
    min-height: 59vw;
    padding: 10vw 0;
    position: relative;

    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.6vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3vw;
      font-size: 3.2vw;
      color: #283a97;
      margin: 8vw 0 0 0;
      text-align: center;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);

      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
    }

    .--desc {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 500 !important;
      text-transform: uppercase;
      line-height: 3.5vw;
      font-size: 1.3vw;
      color: #283a97;
      margin: 6vw 0 0 0;
      text-align: center;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);

      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
    }
  }

  .overlay-shape1 {
    background: url("../images/aboutus/About_BG-Shapes-01.png") bottom 11vw left;
    background-repeat: no-repeat;
    background-size: 103vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 66vw;
    top: 0;
  }

  .vision-bg {
    background: url("../images/aboutus/About_BG-02.png") top -3vw center;
    background-repeat: no-repeat;
    background-size: 100vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 66vw;
    top: 0;
  }

  .overlay-shape {
    background: url("../images/aboutus/About_BG-Shapes-02.png") top -2vw center;
    background-repeat: no-repeat;
    background-size: 116vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 66vw;
    top: 0;
  }

  .mission-bg {
    background: url("../images/aboutus/About_BG-03.png") top center;
    background-repeat: no-repeat;
    background-size: 118vw 70vw;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 68vw;
    top: -4vw;
  }

  .section4-bg {
    background: url("../images/aboutus/About_BG-04.png") top center;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 101vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 50vw;
    top: 0vw;
  }

  .ourvalues-bg {
    background: url("../images/aboutus/About_BG-04.png") top center;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 101vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 50vw;
    top: 0vw;
  }

  .section6-bg {
    background: url("../images/aboutus/About_BG-05.png") bottom center;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-size: 101vw auto;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 50vw;
    top: 0vw;

    &::before {
      content: "";
      top: 0;
      right: 0;
      position: absolute;
      width: 0;
      height: 0;
      border-right: 50vw solid transparent;
      border-bottom: 20vw solid #283a97;
      transform: rotate(180deg);
    }
  }

  .wedig-bg {
    background-repeat: no-repeat;
    background-size: 99vw 59vw;
    transition: background 0.3s;
    background-attachment: scroll;
    background: url("../images/aboutus/About_BG-06.png") bottom center;
    image-rendering: -webkit-optimize-contrast;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 59vw;
    top: 0vw;
  }

  .slideshow {
    border: 0;
    box-shadow: none;
    margin: 0;
    min-width: 100%;
    position: relative;
    width: 100%;
    height: 55vw;
  }

  .content {
    // background: url(https://vulchivijay.files.wordpress.com/2015/01//grid.png) repeat scroll 0 0;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    // z-index: -5 !important;
  }

  .parallax-bg {
    background: url("../../assets/images/civilinfrastructure/Group 805.jpg")
      repeat scroll 0 0;
    background-size: 100vw 100%;
    left: 0;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    background-repeat: no-repeat;
  }

  .slideshow input {
    bottom: 13vw;
    cursor: pointer;
    height: 25px;
    left: 30vw;
    opacity: 0;
    position: absolute;
    width: 25px;
    z-index: 1001;
    border-radius: 100px;
  }

  .slideshow input + label {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    bottom: 13vw;
    cursor: pointer;
    height: 25px;
    left: 30vw;
    opacity: 1;
    position: absolute;
    width: 25px;
    border-radius: 100px;
    -webkit-transition: background-color linear 0.1s;
    -moz-transition: background-color linear 0.1s;
    transition: background-color linear 0.1s;
    z-index: 1000;
    border: 1px solid #676ab1;
  }

  .slideshow input:checked + label {
    background-color: #fff;
    background-color: #676ab1;
  }

  .selector-1,
  .button-label-1 {
    margin-left: -36px;
  }

  .selector-2,
  .button-label-2 {
    margin-left: 1.5vw;
  }

  .selector-4,
  .button-label-4 {
    margin-left: 18px;
  }

  .selector-5,
  .button-label-5 {
    margin-left: 36px;
  }

  .arrow {
    background: transparent
      url("https://vulchivijay.files.wordpress.com/2015/01/arrows.png")
      no-repeat;
    cursor: pointer;
    display: none;
    height: 38px;
    margin-top: -19px;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    -webkit-transition: opacity linear 0.3s;
    -moz-transition: opacity linear 0.3s;
    transition: opacity linear 0.3s;
    width: 28px;
    z-index: 1000;
  }

  .arrow:hover {
    opacity: 1;
  }

  .arrow:active {
    margin-top: -18px;
  }

  .selector-1:checked ~ .arrow.a2,
  .selector-2:checked ~ .arrow.a3,
  .selector-3:checked ~ .arrow.a4,
  .selector-4:checked ~ .arrow.a5 {
    background-position: top right;
    display: block;
    right: 15px;
  }

  .selector-2:checked ~ .arrow.a1,
  .selector-3:checked ~ .arrow.a2,
  .selector-4:checked ~ .arrow.a3,
  .selector-5:checked ~ .arrow.a4 {
    background-position: top left;
    display: block;
    left: 15px;
  }

  .slideshow input:checked ~ .content {
    background-color: #f5f5f5;
    -webkit-transition: background-position linear 0.6s,
      background-color linear 0.8s;
    -moz-transition: background-position linear 0.6s,
      background-color linear 0.8s;
    transition: background-position linear 0.6s, background-color linear 0.8s;
  }

  .slideshow input:checked ~ .content .parallax-bg {
    -webkit-transition: background-position linear 0.7s;
    -moz-transition: background-position linear 0.7s;
    transition: background-position linear 0.7s;
  }

  input.selector-1:checked ~ .content {
    background-position: 0 0;
  }

  input.selector-2:checked ~ .content {
    background-position: -100px 0;
  }

  input.selector-3:checked ~ .content {
    background-position: -200px 0;
  }

  input.selector-4:checked ~ .content {
    background-position: -300px 0;
  }

  input.selector-5:checked ~ .content {
    background-position: -400px 0;
  }

  input.selector-1:checked ~ .content .parallax-bg {
    background-position: 0 -38vw;
  }

  input.selector-2:checked ~ .content .parallax-bg {
    background-position: 0 -28vw;
  }

  .slider {
    height: 200%;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    -webkit-transition: top ease-in 0.8s;
    -moz-transition: top ease-in 0.8s;
    transition: top ease-in 0.8s;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media not all and (min-resolution: 0.001dpcm) {
      height: 100% !important;
    }
  }

  .slider > li {
    box-sizing: border-box;
    color: #fff;
    display: table;
    float: left;
    height: 100%;
    opacity: 0.4;
    -webkit-transition: opacity ease-in 0.4s 0.8s;
    -moz-transition: opacity ease-in 0.4s 0.8s;
    transition: opacity ease-in 0.4s 0.8s;
    width: 100%;
  }

  .slider > li img {
    box-sizing: border-box;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
  }

  input.selector-1:checked ~ .content .slider {
    top: 0;
  }

  input.selector-2:checked ~ .content .slider {
    top: -100%;
  }

  input.selector-3:checked ~ .content .slider {
    top: -150%;
  }

  input.selector-4:checked ~ .content .slider {
    top: -300%;
  }

  input.selector-5:checked ~ .content .slider {
    top: -400%;
  }

  input.selector-1:checked ~ .content .slider > li:first-child,
  input.selector-2:checked ~ .content .slider > li:nth-child(2),
  input.selector-3:checked ~ .content .slider > li:nth-child(3),
  input.selector-4:checked ~ .content .slider > li:nth-child(4),
  input.selector-5:checked ~ .content .slider > li:nth-child(5) {
    opacity: 1;
  }

  .slider > li > div {
    display: table-cell;
    padding: 0;
  }

  /* media query*/
  // @media (max-width: 680px) {
  //     .slider > li > .slider-content {
  //         padding: 20px;
  //     }
  // }
  .--mob {
    display: none;
  }

  @media (max-width: 1500px) {
    .--container-5 {
      .splide__pagination {
        bottom: 17vw;
      }
    }

    .--container-7 {
      .splide__pagination {
        bottom: 10vw;
      }
    }
  }

  @media (max-width: 1400px) {
    .--container-5 {
      .splide__pagination {
        bottom: 15vw;
      }
    }

    .--container-7 {
      .splide__pagination {
        bottom: 8vw;
      }
    }
  }

  @media (max-width: 992px) {
    margin: 0;

    .--mob {
      display: block;
    }

    .--header-container {
      height: 85vw;
      background-size: 120vw auto, auto 140vw;
      padding: 0;

      .--title {
        font-size: 1.5em;
        margin: 2.4vw 0 30px 0;
        word-spacing: 100vw;
        line-height: 1.2;
      }

      .--desc {
        font-size: 0.875em;

        br {
          display: none;
        }
      }

      .--about-content {
        .col-md-5 {
          width: 60.666667%;
        }
      }

      .--build-project-list {
        figure {
          figcaption {
            span {
              font-size: 0.75em;
              width: 95%;
            }
          }
        }
      }

      .--carousel-container {
        position: relative;
        max-width: 85vw;
        margin: 0 auto;
      }

      .--btn-carousel-container {
        position: absolute;
        width: 100%;
        top: 0;
        z-index: 10;
        height: 100%;
      }

      button {
        &.carousel-control-prev {
          background: url("../images/mobile/home/Obj_Arrow-02.png") center;
          transition: background 0.3s;
          background-attachment: local;
          background-repeat: no-repeat;
          background-size: contain;
          width: 6vw;
          display: block;
          padding: 0;
          cursor: pointer;
          height: 100% !important;
          opacity: 1;
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;

          span {
            display: none !important;
          }
        }

        &.carousel-control-next {
          background: url("../images/mobile/home/Obj_Arrow-01.png") center;
          transition: background 0.3s;
          background-attachment: local;
          background-repeat: no-repeat;
          background-size: contain;
          width: 6vw;
          display: block;
          padding: 0;
          cursor: pointer;
          height: 100% !important;
          opacity: 1;
          outline: 0 !important;
          box-shadow: none !important;
          border: 0 !important;

          span {
            display: none !important;
          }
        }
      }

      .--carousel-mb {
        .carousel-inner {
          .carousel-indicators {
            top: 5vw;
          }
          .carousel-caption {
            position: relative;
            right: unset;
            left: unset;

            h3 {
              font-family: "gill sans", sans-serif !important;
              letter-spacing: 0.5vw;
              font-weight: 600 !important;
              text-transform: uppercase;
              line-height: 1.2;
              font-size: 1.5em !important;
              color: #283a97;
              margin: 50px 0 0 0;
              text-align: left;
              min-width: 300px;
              max-width: 100%;
            }

            p {
              font-family: "gill sans medium", sans-serif;
              font-weight: 400 !important;
              margin-bottom: 0;
              font-size: 1em !important;
              color: #676ab1;
              text-transform: uppercase;
              line-height: 1.8;
              min-width: 250px;
              margin: 5vw auto 0 auto;
              text-align: left;
              max-width: 60vw;

              br {
                display: none;
              }
            }
          }
        }

        a.carousel-control-next,
        a.carousel-control-prev {
          display: none;

          span {
            display: none !important;
          }
        }

        .--btn-check-our-work {
          font-family: "gill sans medium", sans-serif !important;
          letter-spacing: 0.3vw;
          font-size: 0.875em;
          color: #283a97;
          font-weight: 600;
          padding: 13px 20px;
          border-radius: 0;
          outline: 0 !important;
          box-shadow: none !important;
          margin-top: 5vw;
        }
      }
    }

    .parallax-bg {
      background-size: auto 124vw;
    }

    .slideshow {
      height: 100%;

      input {
        left: 32vw;
        bottom: 24vw;

        + label {
          left: 32vw !important;
          bottom: 24vw !important;
        }
      }
    }

    .overlay-shape1 {
      height: 96vw;
    }

    .vision-bg {
      background-size: 100vw 700px;
      height: 700px;
    }

    .overlay-shape {
      background-size: 100vw 700px;
      height: 700px;
    }

    .--container-2 {
      padding: 100px 0 !important;
      height: 700px !important;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        &.--title {
          font-size: 1.5em;
          margin-top: 0;
        }
      }

      p {
        &.--sub-title {
          font-size: 0.875em;
          line-height: 1.2;
          margin: 50px 0 0 0;
        }
      }

      h1 {
        &.--title {
          margin: 50px 0 0 0;
          font-size: 1.5em;
        }
      }
    }

    .mission-bg {
      height: 700px;
      background-size: 150vw 700px;
      top: 0 !important;
    }

    .--container-3 {
      margin-top: -5vw;
      padding: 100px 0;
      height: 700px;
      display: flex;
      justify-content: center;
      align-items: center;

      // background-size: 118vw 100%;
      // background-repeat: no-repeat;
      img {
        &.--img {
          left: 8vw;
          top: 49vw;
        }
      }

      h2 {
        &.--title {
          margin: 0;
          font-size: 1.5em;
          line-height: initial;
        }
      }

      p {
        &.--sub-title {
          margin-top: 50px;
          font-size: 0.875em;
        }
      }
    }

    .--container-5 {
      height: fit-content;
      background: url("../images/mobile/aboutus/AboutUs_BG-02.png") top center;
      background-repeat: no-repeat;
      background-size: 100vw 140vw;
      transition: background 0.3s;
      background-attachment: scroll;
      padding: 13vw 0 10vw 0;
      margin-top: -1vw;
      height: 120vw;

      .--max {
        max-width: 90%;
      }

      .--bg-1,
      .--bg-2 {
        display: none;
      }

      h1 {
        padding-bottom: 10vw;
        font-size: 0.875em !important;
      }

      .--title {
        margin: 0;
        font-size: 1.5em;
        line-height: initial;
        font-family: "gill sans medium", sans-serif !important;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
      }

      .splide {
        height: fit-content;
        margin: 0;
        width: 100%;

        .splide__track {
          top: 0;

          .splide__list {
            .splide__slide {
              height: 50vw !important;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              width: 100% !important;
            }
          }

          img {
            width: 60% !important;
            height: 55vw;
          }
        }
      }

      .splide__pagination {
        bottom: -5vw;
      }

      .--right-container {
        top: 0;
        position: relative;
        left: 0;
        width: 44%;
        height: 40vw;
        padding-top: 17vw;

        h5 {
          &.--title {
            font-size: 3vw;
            margin-bottom: 0px;
          }
        }

        hr {
          width: 35vh;
        }

        p {
          &.--desc {
            font-size: 12px;
            line-height: 1.4;
            margin: 9.5vw auto 0 auto;
          }
        }
      }
    }

    .--container-7 {
      height: fit-content;
      background: url("../images/mobile/aboutus/AboutUs_BG-03.png") top center;
      background-repeat: no-repeat;
      background-size: 100vw 120vw;
      transition: background 0.3s;
      background-attachment: scroll;
      padding: 25vw 0 10vw 0;
      margin-top: -25vw;
      height: 120vw;

      .--max {
        max-width: 90%;
      }

      h1 {
        &.--title {
          margin-bottom: 18vw;
          font-size: 0.875em;
        }
      }

      .--mob {
        display: block;
      }

      .--bg-1 {
        display: none;
      }

      .--title {
        margin: 0;
        font-size: 1.5em;
        line-height: initial;
        font-family: "gill sans medium", sans-serif !important;
        text-transform: uppercase;
        color: #ffffff;
        text-align: center;
      }

      .splide {
        height: fit-content;
        margin: 0;

        .splide__track {
          top: 0;

          .splide__list {
            .splide__slide {
              height: 55vw !important;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              width: 100% !important;
            }
          }

          img {
            width: 60% !important;
            height: 50vw;
          }
        }
      }

      .splide__pagination {
        bottom: 0vw;
      }

      .--right-container {
        top: 0;
        position: relative;
        left: 0;
        width: 44%;
        height: 40vw;
        padding-top: 10vw;

        h5 {
          &.--title {
            font-size: 3vw;
            margin-bottom: 0px;
          }
        }

        hr {
          width: 35vh;
        }

        p {
          &.--desc {
            font-size: 12px;
            line-height: 1.4;
            margin: 9.5vw auto 0 auto;
          }
        }
      }
    }

    .wedig-bg {
      height: 85vw;
    }

    .--container-8 {
      height: 600px;
      // background: url("../images/mobile/aboutus/AboutUs_BG-04.png") top center;
      // background-repeat: no-repeat;
      // background-size: 100vw 100%;
      // transition: background 0.3s;
      // background-attachment: scroll;
      padding: 100px 0;
      margin-top: -1vw;

      .--title {
        font-size: 1.5em;
        margin-bottom: 50px;
      }

      .--desc {
        font-size: 0.875em;
        line-height: 2.5 !important;

        br {
          display: none;
        }
      }

      .wedig-bg {
        height: 600px;
        background-size: 140vw 100%;
      }
    }
  }

  @media (max-width: 880px) {
    .slideshow {
      input {
        bottom: 15vw;

        + label {
          bottom: 15vw !important;
        }
      }
    }
  }

  @media (max-width: 800px) {
    // .--container-7 {
    //     height: 110vw;
    // }
  }

  @media (max-width: 769px) {
    .slideshow {
      input {
        bottom: 15vw;
        left: 22vw;

        + label {
          left: 22vw !important;
          bottom: 15vw !important;
        }
      }
    }

    .overlay-shape1 {
      background: url("../images/aboutus/About_BG-Shapes-01.png") bottom -5vw left;
      background-size: 103vw auto;
      background-repeat: no-repeat;
      height: 100vw;
    }

    .parallax-bg {
      background-position: 0 -22vw !important;
    }

    .--header-container {
      height: 100vw !important;

      button {
        &.carousel-control-next {
          width: 9vw !important;
        }

        &.carousel-control-prev {
          width: 9vw !important;
        }
      }
    }

    // .--container-7 {
    //     height: 115vw !important;
    //     .splide {
    //         margin: 4vw auto 0 auto;
    //     }
    // }
  }

  @media (max-width: 700px) {
    .--header-container {
      height: 120vw !important;
    }

    .parallax-bg {
      background-size: auto 145vw;
    }

    .overlay-shape1 {
      height: 120vw;
    }

    .slideshow input {
      left: 22vw !important;
      bottom: 25vw !important;

      + label {
        left: 22vw !important;
        bottom: 25vw !important;
      }
    }

    .--header-container {
      .--build-project-list {
        figure {
          figcaption {
            span {
              width: 80%;
            }
          }
        }
      }

      .--about-content {
        padding-top: 25vw;

        .col-md-5 {
          width: 100%;
        }
      }
    }

    .--container-5 {
      .--right-container {
        padding-top: 16vw;

        h5 {
          &.--title {
            word-break: break-word !important;
            line-height: initial;
          }
        }
      }

      .splide__pagination {
        bottom: -20vw !important;
      }

      .splide__pagination__page {
        height: 20px !important;
        width: 20px !important;

        &.is-active {
          height: 25px !important;
          width: 25px !important;
        }
      }
    }

    .--container-7 {
      .splide__pagination {
        bottom: -20vw;
      }

      .splide__pagination__page {
        height: 20px !important;
        width: 20px !important;

        &.is-active {
          height: 25px !important;
          width: 25px !important;
        }
      }
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 590px) {
      .slider {
        height: 100% !important;

        @media not all and (min-resolution: 0.001dpcm) {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 590px) {
    .--header-container {
      height: 700px !important;

      .--build-project-list {
        figure {
          margin-right: 10px !important;

          figcaption {
            span {
              font-size: 3vw !important;
              margin-right: 0 !important;
              width: 100% !important;
            }
          }
        }
      }

      .--max {
        max-width: 85%;
      }
    }

    .slideshow {
      input {
        left: 30vw !important;

        + label {
          left: 30vw !important;
        }
      }
    }

    .overlay-shape {
      background-size: 100vw 500px;
      height: 500px;
    }

    .vision-bg {
      background-size: 100vw 500px;
      height: 500px;
    }

    .--container-2 {
      padding: 100px 0 !important;
      height: 500px !important;
    }

    .--container-3 {
      height: 500px !important;

      img {
        &.--img {
          top: 27vw;
        }
      }
    }

    .mission-bg {
      height: 500px;
      background-size: 150vw 500px;
    }

    .selector-1,
    .button-label-1 {
      margin-left: -55px;
    }

    .slider {
      height: 200%;
    }

    .overlay-shape1 {
      height: 700px;
    }

    .parallax-bg {
      background-size: auto 135vh;
      background-position: -70vw -50vw !important;
    }

    .--container-5 {
      height: 650px;
      background-size: 100vw 650px;
    }

    .--container-7 {
      height: 650px;
      background-size: 100vw 650px;
    }
  }
}
