/*
*
|  Page Name       : contactus.scss
|  Description     : scss for contact us page use
|  Created by      : May Altamerano
|  Date Created    : July 3 2021
|  Last Update by  : May Altamerano
|  Last update     : July 3 2021
*
*/
.modal-dialog {
  max-width: 50vw;
  margin: 5vw auto 2vw auto;
}
.--contact-us {
  position: relative;

  .--bg-1 {
    padding: 10vw 0 0 0;
    min-height: 50vw;
    width: 100%;
    .--max {
      max-width: 86%;
      img {
        margin: 0 auto;
        display: block;
        height: 13vw;
        width: 13vw;
        object-fit: contain;
        image-rendering: pixelated;
      }
    }
    h1.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.4vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1;
      font-size: 2vw;
      color: #283a97;
      margin: 4vw 0 10.5vw 0;
      text-align: center;
    }
    h3.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 1.19vw;
      color: #283a97;
      margin: 0 0 0.5vw 0;
      text-align: center;
    }
    p.--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 2.4vw;
      font-size: 1.2vw;
      color: #283a97;
      line-height: 1.4vw;
      text-align: center;
      letter-spacing: 0.17vw;
    }
    .header-shapes {
      position: absolute;
      height: 100vw;
      width: 100%;
      top: 10vw;
      overflow: hidden;
      &::before {
        content: "";
        height: 7.8vw;
        width: 57.5vw;
        background-color: transparent;
        border: 1px solid #283a97;
        position: absolute;
        top: -2vw;
        left: -42vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(321deg, 16.5deg);
      }
      &::after {
        content: "";
        height: 11.8vw;
        width: 57.5vw;
        background-color: transparent;
        border: 1px solid #283a97;
        position: absolute;
        top: 40vw;
        right: -44vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(321deg, 16.5deg);
      }
    }
  }
  .--bg-2 {
    padding: 27.5vw 0 0 0;
    min-height: 80vw;
    width: 100%;
    position: relative;
    .--max {
      max-width: 100%;
      min-height: 42vw;
      z-index: 2;
      position: relative;
    }
    h3.--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.2vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.1;
      font-size: 1.05vw;
      color: #ffffff;
      margin: 0 0 3.5vw 0;
      text-align: center;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
    }
    form {
      margin-left: 5vw;
      .form-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        transform: scale(0) !important;
        transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
        &.is-reveal {
          transform: scale(1) !important;
          transition-delay: 0.3s;
        }
      }
      label {
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.1vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 1.1;
        font-size: 0.8vw;
        color: #ffffff;
        margin: 0 0.5vw 0.5vw 0;
        text-align: right;
      }
      input {
        color: #ffffff;
        border: 1px solid #ffffff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
        height: 4vw;
        padding: 1vw;
        background-color: transparent !important;
        background: transparent !important;
        font-family: "gill sans medium", sans-serif !important;
        letter-spacing: 0.1vw;
      }
      textarea {
        color: #ffffff;
        border: 1px solid #ffffff !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        outline: 0 !important;
        height: 4vw;
        padding: 1vw;
        background-color: transparent !important;
        background: transparent !important;
        resize: none;
        font-family: "gill sans medium", sans-serif !important;
        letter-spacing: 0.1vw;
      }
      .--btn-send {
        background: url("../images/contactus/Contact_Button-01.png") top center;
        background-repeat: no-repeat;
        transition: background 0.3s;
        image-rendering: -webkit-optimize-contrast;
        width: 15vw;
        height: 60px;
        background-position: center;
        background-size: contain;
        border: 0 !important;
        border-radius: 0 !important;
        outline: 0 !important;
        display: block;
        margin: 5vw auto 0 11.7vw;
        outline: 0 !important;
        box-shadow: none !important;
        transform: scale(0) !important;
        transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
        &.is-reveal {
          transform: scale(1) !important;
          transition-delay: 0.3s;
        }
        &:focus {
          outline: 0 !important;
        }
      }
    }
    .--map-container {
      width: 35vw;
      height: 30vw;
      padding-top: 2vw;
      position: relative;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
      pointer-events: none;

      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
        position: all;
      }
      iframe {
        margin-left: -2.5vw;
        width: 37.5vw;
      }
      img {
        width: 100%;
      }
    }
    .header-shapes {
      position: absolute;
      height: 100vw;
      width: 100%;
      top: 3vw;

      overflow: hidden;
      &::before {
        content: "";
        height: 10.8vw;
        width: 57.5vw;
        background-color: transparent;
        border: 1px solid #ffffff;
        position: absolute;
        top: -2vw;
        left: -42vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(321deg, 16.5deg);
      }
      &::after {
        content: "";
        height: 11.8vw;
        width: 67.5vw;
        background-color: #9c9eca8f;
        position: absolute;
        top: 12vw;
        right: -27vw;
        transition: height 0.3s;
        transition: width 0.3s;
        transform: skew(318deg, 15deg);
      }
    }
  }

  .header-bg {
    background: url("../images/contactus/Contact_BG-01.png");
    // background-size: 105vw auto !important;
    background-size: 105% !important;
    background-position: center;
    background-repeat: no-repeat;
    // background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    position: absolute;
    height: 80vw;
    width: 100%;
    top: 0;
    z-index: -3;
  }

  .form-bg {
    position: absolute;
    top: -5vw;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    &::before {
      content: "";
      border-top: 27vw solid transparent;
      border-left: 100vw solid #283a97;
      height: 100% !important;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1024px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 155vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .header-bg {
      display: none;
    }
    .form-bg {
      background-size: 193vw 194vw !important;
      background-repeat: no-repeat;
      background-attachment: scroll;
      transition: background 0.3s;
      image-rendering: -webkit-optimize-contrast;
      position: absolute;
      height: 170vw;
      width: 100%;
      // top: -20vw;
      z-index: 0;
    }
    .--bg-1 {
      padding: 200px 0 0 0;
      .--max {
        max-width: 90%;
      }
      h1.--title {
        font-size: 1.5em;
      }
      img {
        height: 10vw;
        width: 10vw;
      }
      h3 {
        &.--title {
          font-size: 0.875em;
        }
      }
      p {
        &.--desc {
          font-size: 0.875em;
          line-height: 1.4;
        }
      }
      .row {
        &:nth-child(2) {
          .col-md-4 {
            width: 50%;
            max-width: 50%;
            &:last-child {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
    .--bg-2 {
      padding: 30.5vw 0 0 0;
      .--max {
        z-index: 2;
        position: relative;
      }
      .col-md-6 {
        width: 100%;
        max-width: 100%;
      }
      h3 {
        &.--title {
          font-size: 1.5em;
          margin: 0 0 6.5vw 0;
        }
      }
      form {
        label {
          font-size: 0.875em;
        }
        input {
          height: 60px;
        }
        textarea {
          height: 60px;
        }
        .--btn-send {
          width: 215px;
          height: 45px;
          margin: 5vw auto 0 23.7vw;
        }
      }
      .--map-container {
        width: 100%;
        height: 50vw;
        padding-top: 2vw;
        position: relative;
        display: block;
        margin: 100px auto;
        iframe {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 920px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 165vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
  }
  @media (max-width: 740px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 180vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .form-bg {
      height: 195vw;
    }
  }
  @media (max-width: 600px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 200vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .--bg-1 {
      padding: 150px 0 100px 0;
      .header-shapes {
        &::before {
          top: 13vw;
        }
      }
    }
  }
  @media (max-width: 566px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 220vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .form-bg {
      background-size: 350vw 370vw !important;
      height: 320vw !important;
      z-index: 0;
      bottom: 0;
      // top: -44vw;
    }
    .--bg-2 {
      padding: 45.5vw 0 0 0;
      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;
        label {
          text-align: left !important;
        }
      }
    }
  }
  @media (max-width: 500px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 235vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .--bg-2 {
      padding: 45.5vw 0 0 0;
      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;
        label {
          text-align: left !important;
        }
      }
    }
  }
  @media (max-width: 420px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 280vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .--bg-2 {
      padding: 45.5vw 0 0 0;
      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;
        label {
          text-align: left !important;
        }
        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }
  }
  @media (max-width: 400px) {
    background: url("../images/contactus/Contact_BG-01.png") top -8vw right;
    background-size: 280vw auto;
    background-repeat: no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    .--bg-2 {
      padding: 45.5vw 0 0 0;
      form {
        margin-left: 0;
        width: 90%;
        margin: 0 auto;
        label {
          text-align: left !important;
        }
        .--btn-send {
          margin: 5vw auto 0 auto;
        }
      }
    }
  }
}
