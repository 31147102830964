/*
*
|  Page Name       : propertydevelopment.scss
|  Description     : scss for property development page use
|  Created by      : May Altamerano
|  Date Created    : July 2 2021
|  Last Update by  : May Altamerano
|  Last update     : July 2 2021
*
*/
.--property-development {
  position: relative;
  /* &::after {
    content: "";
    position: absolute;
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../images/propertydevelopment/PropertyDev_Shapes-01-2.png")
      top 31vw left -16vw;
    height: 30vw;
    width: 30%;
    background-position: -17vw 0vw;
    background-size: 27.5vw auto;
    background-repeat: no-repeat;
    z-index: 2;
  } */
  .--bg-1 {
    // padding: 10vw 0 0 0;
    min-height: 60vw;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.4vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3.1vw;
      font-size: 2vw;
      color: #283a97;
      margin: 6vw 0 2.5vw 0;
      text-align: center;
    }
    .--desc {
      font-family: "gill sans medium", sans-serif;

      // margin-bottom: 9.4vw;
      font-size: 1.25vw;
      color: #676ab1;
      line-height: 2.4vw;
      text-align: center;
      letter-spacing: 0.25vw;
    }
  }
  .--bg-2 {
    // padding: 10vw 0 0 0;
    min-height: 45vw;
    // margin-top: -9.1vw;
    width: 100%;
    position: relative;

    .carousel {
      position: relative;

      &::after {
        background: url("../images/propertydevelopment/PropertyDev_Shapes-01-6.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;

        content: "";
        z-index: 2;
        width: 100%;
        height: 20vw;
        position: absolute;
        bottom: 0;
        left: 0;

        pointer-events: none;

        mix-blend-mode: multiply;
      }
    }

    .svg {
      position: absolute;
      z-index: 2;
      pointer-events: none;

      &--1 {
        top: -20%;
        left: 0;
        width: 35vw;
        height: 40vw;
        mix-blend-mode: multiply;
      }

      &--2 {
        top: -38%;
        right: 0;
        width: 20vw;
        height: 20vw;
      }

      &--3 {
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .header-bg {
    background: url("../images/propertydevelopment/PropertyDev_BG-01.png") top -1vw
      center;
    background-size: 125vw auto;
    background-repeat: no-repeat, no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    image-rendering: -webkit-optimize-contrast;
    position: absolute;
    height: 50vw;
    width: 100%;
    top: -2vw;
    z-index: -3;
  }
  .header-shape {
    position: absolute;
    top: -4.5vw;
    left: 0;
    right: 0;
    background: url("../images/propertydevelopment/PropertyDev_Shapes-01-1.png")
      top 0 left -16vw;
    height: 80vw;
    width: 30%;
    background-position: -17vw 0vw;
    background-size: 43.5vw auto;
    background-repeat: no-repeat;
    z-index: 2;
  }

  .header-shape1 {
    position: absolute;
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../images/propertydevelopment/PropertyDev_Shapes-01-2.png")
      top 31vw left -16vw;
    height: 30vw;
    width: 30%;
    background-position: -14vw -6vw;
    background-size: 27.5vw auto;
    background-repeat: no-repeat;
    z-index: 2;
  }

  @media (max-width: 992px) {
    background-size: cover;
    .header-bg {
      background-size: cover !important;
      height: 175vw;
    }
    &::before {
      z-index: -1;
    }
    .--bg-1 {
      min-height: 60vw;
      padding: 150px 0 0 0;
      .--title {
        font-size: 1.5em;
      }
      .--desc {
        font-size: 1.125em;
        line-height: 1.4;
        br {
          display: none;
        }
      }
    }
    .--bg-2 {
      /* .carousel {
                height: 100vw;
                
                .carousel-inner {
                    height: 100vw;
                    img {
                        height: 100vw;
                    }
                }
                .carousel-caption {
                    width: 90%; 
                    left: 50px;
                    .--title {
                        font-size: 	1.125em;
                        line-height: 1.6;
                    }
                    .--desc {
                        font-size: 	0.875em;
                        line-height: 1.6;
                        br {
                            display: none;
                        }
                    }
                    .--hr {
                        margin-left: 5.7vw;
                        width: 80vw;
                    }
                    span.--title,
                    span {
                        font-size: 	0.875em !important;
                    }
                    .--btn-view-project {
                        font-size: 	0.875em !important;
                    }
                    .--bottom-detail {
                        margin-bottom: 30px;
                    }
                }
                .carousel-indicators {
                    width: 92vw;
                    bottom: 25vw;
                    li {
                        height: 21px;
                        width: 21px;
                    }
                }
            }
            .--carousel-container {
                position: relative;
                .--btn-carousel-container {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    z-index: 10;
                    height: 50%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
                .carousel-indicators {
                    display: none;
                }
                button {
                    &.carousel-control-prev {
                        background: url("../images/licensesandregistrations/Licenses_Button-03.png") center;
                        transition: background 0.3s;
                        background-attachment: local;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 9vw;
                        display: block;
                        padding: 0;
                        cursor: pointer;
                        height: 9vw;
                        opacity: 1;
                        outline: 0 !important;
                        box-shadow: none !important;
                        border: 0 !important;
                        margin-left: 3vw;
                        margin-top: auto;
                        span {
                            display: none !important;
                        }
                    }
                    &.carousel-control-next {
                        background: url("../images/licensesandregistrations/Licenses_Button-04.png") center;
                        transition: background 0.3s;
                        background-attachment: local;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 9vw;
                        display: block;
                        padding: 0;
                        cursor: pointer;
                        height: 9vw;
                        opacity: 1;
                        outline: 0 !important;
                        box-shadow: none !important;
                        border: 0 !important;
                        margin-right: 3vw;
                        margin-top: auto;
                        span {
                            display: none !important;
                        }
                    }
                }
                .--carousel-mb {
                    .carousel-inner {
                        img {
                            width: 100% !important;
                            margin: 0 auto;
                        }
                        .carousel-caption {
                            position: absolute;
                            right: 13vw;
                            left: 13vw;
                            margin-top: 8vw;
                            width: auto !important;
                            h3 {
                                font-size: 	1.125em;
                                margin-bottom: 1.5vw;
                                letter-spacing: 0.4vw;
                            }
                            p {
                                font-size: 0.875em;
                                letter-spacing: 0.2vw;
                                br {
                                    display: none;
                                }
                            }
                            .--hr {
                                width: 58vw;
                            }
                        }
                    }
                    a.carousel-control-next,
                    a.carousel-control-prev {
                        display: none;
                        span {
                            display: none !important;
                        }
                    }
                }
            } */
    }
  }
  @media (max-width: 825px) {
    .--bg-1 {
      min-height: 70vw;
    }
  }
  @media (max-width: 700px) {
    .--bg-1 {
      min-height: 80vw;
    }
    .--bg-2 {
      /* .carousel {
                height: 115vw;
                .carousel-inner {
                    height: 115vw;
                    img {
                        height: 115vw;
                    }
                }
                .carousel-caption {
                    left: 25px;
                }
            } */
    }
  }
  @media (max-width: 570px) {
    .--bg-1 {
      min-height: 90vw;
    }
    .--bg-2 {
      /* .carousel {
                height: 140vw;
                .carousel-inner {
                    height: 140vw;
                    img {
                        height: 140vw;
                    }
                }
                .carousel-caption {
                    left: 25px;
                }
                .carousel-indicators {
                    bottom: 28vw;
                }
            } */
    }
  }
  @media (max-width: 500px) {
    .--bg-1 {
      min-height: 120vw;
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .--title {
        max-width: 95%;
        margin: 5vw auto 5vw auto;
        line-height: inherit;
      }
      .--desc {
        max-width: 95%;
        margin: 0 auto;
      }
    }
    .--bg-2 {
      /* .carousel {
                height: 200vw;
                .carousel-inner {
                    height: 200vw;
                    img {
                        height: 200vw;
                    }
                }
                .carousel-caption {
                    left: 25px;
                    padding-bottom: 35.3vw;
                }
                .carousel-indicators {
                    bottom: 50vw;
                }
            } */
    }
  }
}
