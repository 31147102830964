/*
*
|  Page Name       : licensesandregistrations.scss
|  Description     : scss for licenses and registrations page use
|  Created by      : May Altamerano
|  Date Created    : July 2 2021
|  Last Update by  : May Altamerano
|  Last update     : July 2 2021
*
*/
.--licenses-and-registrations {
  width: 100%;
  overflow-x: hidden;

  .--bg-1 {
    padding: 10vw 0 0vw 0;
    min-height: 50vw;
    width: 100%;
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3.1vw;
      font-size: 2.3vw;
      color: #283a97;
      margin: 10.3vw 0 3.4vw 0;
      text-align: center;
    }
    .--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 9.4vw;
      font-size: 1vw;
      color: #676ab0;
      line-height: 1.9vw;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .--bg-2 {
    background-color: #676ab0;
    width: 100%;
    position: relative;
    z-index: 3;

    margin-top: 15vw;

    &::before,
    &::after {
      z-index: -1;
    }

    &::before {
      content: "";
      background: url("../images/licensesandregistrations/licenses-bg-before.png")
        bottom center / 100vw no-repeat;

      position: absolute;
      bottom: 93%;
      left: 0;
      width: 100%;
      height: 40vw;

      pointer-events: none;
    }

    &::after {
      content: "";
      background: url("../images/licensesandregistrations/licenses-bg-after.png")
        bottom center / 100vw no-repeat;

      position: absolute;
      top: 86%;
      left: 0;
      width: 100%;
      height: 40vw;
    }

    .carousel {
      margin-top: 10vw;
      img {
        height: 100%;
        object-fit: contain;
        width: 35vw;
        object-position: 0 0;
        margin: 0 auto;
        display: block;
      }
      .carousel-caption {
        position: relative;
        right: unset;
        left: unset;
        bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
      .--caption {
        font-family: "gill sans", sans-serif;
        font-weight: 500 !important;
        margin-top: 2vw;
        font-size: 1vw;
        color: #ffffff;
        line-height: 1.9vw;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2vw;
      }
      .carousel-inner {
        height: 55vw;
      }
      .carousel-control-next,
      .carousel-control-prev {
        height: 50vw;
        span {
          display: none;
        }
      }
      .carousel-control-next {
        background: url("../images/licensesandregistrations/Licenses_Button-02.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 5vw;
        image-rendering: -webkit-optimize-contrast;
        right: 5vw;
      }
      .carousel-control-prev {
        background: url("../images/licensesandregistrations/Licenses_Button-01.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 5vw;
        image-rendering: -webkit-optimize-contrast;
        left: 5vw;
      }
    }
  }
  .--bg-3 {
    margin-top: 25vw;

    padding: 2.6vw 0 20vw 0;
    // min-height: 52vw;
    width: 100%;
    background: url("../images/licensesandregistrations/Licenses_Shapes-03.png")
      bottom 0 right -10vw;
    background-size: 50vw auto;
    background-repeat: no-repeat;
    background: background 0.3s;
    background-attachment: scroll;
    position: relative;
    .carousel {
      margin-top: 0;
    }
    .--max {
      max-width: 85%;
    }
    .--header {
      margin-top: 3vw;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 3.1vw;
      font-size: 2.3vw;
      color: #283a97;
      margin: 0vw 0 2vw 0;
      text-align: center;
    }
    .--desc {
      font-size: 1.1vw;
      text-align: center;
      line-height: 2em;
      letter-spacing: 0.15em;
    }
    .--text-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      height: 100%;
      position: relative;
      right: unset;
      left: unset;
      bottom: 0;
      padding-top: 10vw;
      hr {
        background-color: #283997;
        height: 2px;
        width: 10vw;
        opacity: 1;
      }
      .--desc {
        font-family: "gill sans", sans-serif;
        font-weight: 500 !important;
        margin-bottom: 0;
        margin-top: 1vw;
        letter-spacing: 0.2vw;
        font-size: 1.1vw;
        color: #283997;
        line-height: 2.5vw;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .carousel {
      img {
        height: auto;
        object-fit: contain;
        width: 34vw;
        object-position: 0 0;
        margin: 0 auto;
        display: block;
      }
      .carousel-item {
        height: 54vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .carousel-caption {
        position: relative;
        right: unset;
        left: unset;
        bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
      }
      .--caption {
        font-family: "gill sans", sans-serif;
        font-weight: 500 !important;
        margin-top: 2vw;
        font-size: 1vw;
        color: #676ab0;
        line-height: 1.9vw;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2vw;
      }
      .carousel-inner {
        height: 55vw;
      }
      .carousel-control-next,
      .carousel-control-prev {
        height: 50vw;
        span {
          display: none;
        }
      }
      .carousel-control-next {
        background: url("../images/licensesandregistrations/Licenses_Button-04.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 4vw;
        image-rendering: -webkit-optimize-contrast;
        right: 0;
      }
      .carousel-control-prev {
        background: url("../images/licensesandregistrations/Licenses_Button-03.png")
          center;
        background-size: contain;
        background-repeat: no-repeat;
        background: background 0.3s;
        width: 4vw;
        image-rendering: -webkit-optimize-contrast;
        left: 0;
      }
    }
  }

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    img {
      width: 100%;
    }
    /* background: url("../images/licensesandregistrations/Licenses_BG-01.png") top -40.2vw right -27.5vw;
        background-repeat: no-repeat;
        background-size:  140vw auto !important;
        transition: background 0.3s;
        width: 100%;
        position: absolute;
        z-index: -5;
        height: 80vw;
        top: 0;
        &::after {
            content: "";
            background: url("../images/licensesandregistrations/Licenses_Shapes-01.png")  top -12.7vw right -1.8vw;
            background-repeat: no-repeat;
            background-size: 106vw 80vw !important;
            transition: background 0.3s;
            width: 100%;
            position: absolute;
            z-index: 5;
            height: 80vw;
            top: 0;
        } */
  }
  .license-shape {
    background: url("../images/licensesandregistrations/Licenses_Shapes-03.png")
      bottom left 12vw;
    background-size: 70vw 20vw;
    background-repeat: no-repeat;
    background: background 0.3s;
    background-attachment: scroll;
    height: 21vw;
    width: 59.5vw;
    transform: scaleX(-1) scaleY(-1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .qualityassurance-bg {
    background: url("../images/licensesandregistrations/Licenses_BG-03.png")
      bottom center;
    background-repeat: no-repeat;
    background-size: 100vw auto !important;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    z-index: -5;
    height: 105vw;
    top: -31vw;
  }
  .qualityassurance-shape {
    background: url("../images/licensesandregistrations/Licenses_Shapes-02.png")
      top right -45vw;
    background-repeat: no-repeat;
    background-size: 155vw auto !important;
    transition: background 0.3s;
    width: 100%;
    position: absolute;
    height: 19vw;
    top: -2vw;
    z-index: -2;
  }

  .licenses {
    $spacing: 6vw;
    gap: $spacing;

    & > *:not(:nth-of-type(1))::before {
      //   background: red;
      position: absolute;
      content: "";
      top: -$spacing / 2;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      height: 1px;
      opacity: 0.5;
      background: white;
    }
  }
  .license {
    font-family: "gill sans", sans-serif !important;
    color: white;
    font-size: 1vw;

    position: relative;

    width: 90%;
    margin: 0 auto;

    & > *:nth-child(1) {
      padding: 0 3%;
    }

    h4,
    h5 {
      margin: 0;
    }

    &__content {
      & > *:last-child:not(:only-child) {
        line-height: 3em;
      }

      &--name {
        font-size: 1em;
        letter-spacing: 0.2em;
      }
      &--alt {
        font-size: 0.8em;
        letter-spacing: 0.2em;
        font-family: "gill sans medium", sans-serif !important;
      }
    }
  }
  .licenses-vector__bottom {
    position: absolute;
    bottom: -33%;
    width: 100%;
  }

  .qas {
    $spacing: 6vw;

    margin-top: 12vw;
    gap: $spacing;

    & > * {
      position: relative;

      &:not(:first-child)::before {
        content: "";
        position: absolute;
        top: -$spacing / 2;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 1px;
        background: #283a97;
        opacity: 0.4;
      }
    }
  }
  .qa {
    font-family: "gill sans", sans-serif !important;
    color: #283a97;

    font-size: 1vw;

    h4,
    h5 {
      margin: 0;
    }

    &__image-container {
      height: 2.5vw;
      position: relative;
    }

    &__image {
      height: 100%;

      &--iso {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 7vw;
      }

      &--cidb {
        height: 2vw;
      }

      &--jccd {
        height: 3.5vw;
      }
    }

    &__description {
      &:not(:first-child) {
        margin-top: 1.5em;
      }
    }

    &__sub {
      color: #676ab1;
      font-size: 0.75em;
      letter-spacing: 0.18em;
    }

    &__name {
      color: #676ab1;
      font-size: 1em;
      line-height: 1.3em;
      margin-top: 0.5em !important;
      letter-spacing: 0.1em;

      white-space: pre-line;
    }

    // margin-top: 5vw;
  }

  @media (max-width: 1750px) {
    .--bg-2 {
      //   padding: 5vw 0 5vw 0;
      transition: padding 0.5s;
    }
  }
  @media (max-width: 1450px) {
    .--bg-1 {
      // min-height: 95vh;
      transition: min-height 0.5s;
    }
    .--bg-2 {
      //   padding: 7vw 0;
      transition: padding 0.5s;
    }
  }
  @media (max-width: 1450px) {
    .--bg-1 {
      // min-height: 85vh;
      transition: min-height 0.5s;
    }
  }
  @media (max-width: 1200px) {
    .--bg-1 {
      // min-height: 75vh;
      transition: min-height 0.5s;
    }
  }
  @media (max-width: 992px) {
    overflow-x: hidden;
    .header-bg {
      background: url("../images/licensesandregistrations/Licenses_BG-01.png")
        top -40.2vw right -27.5vw;
      background-repeat: no-repeat;
      background-size: 140vw 200vw !important;
      transition: background 0.3s;
      width: 100%;
      position: absolute;
      z-index: -5;
      height: 165vw;
      top: 0;
      &::after {
        content: "";
        background: url("../images/licensesandregistrations/Licenses_Shapes-01.png")
          bottom -2vw right -18vw;
        background-repeat: no-repeat;
        background-size: 136vw 110vw !important;
        transition: background 0.3s;
        width: 100%;
        position: absolute;
        z-index: 5;
        height: 100%;
        top: 0;
        display: none;
      }
    }
    .licenses-bg {
      background: url("../images/mobile/licenses/Licenses_BG-02.png") top 0
        center;
      background-repeat: no-repeat;
      background-size: 101vw auto !important;
      transition: background 0.3s;
      width: 100%;
      position: absolute;
      z-index: -5;
      height: 123vw;
      top: 0;
    }
    &::before {
      display: none;
    }
    .--bg-1 {
      margin-top: 0;
      min-height: 80vw;
      transition: min-height 0.5s;
      padding: 100px 0;
      .--title {
        font-size: 1.5em;
        line-height: 1.6;
      }
      .--desc {
        font-size: 0.875em;
        line-height: 1.6;
        max-width: 90%;
        margin: 0 auto;
        br {
          display: none;
        }
      }
    }
    .--bg-2 {
      //   padding: 100px 0;
      //   min-height: 123vw;
      .carousel {
        .carousel-inner {
          height: 80vw;
        }
        img {
          width: 50vw;
        }
        .--caption {
          font-size: 1em;
          line-height: 1.6;
        }
        .carousel-control-next,
        .carousel-control-prev {
          width: 7vw;
          &:hover {
            width: 7vw;
          }
        }
        .carousel-control-prev {
          left: 0vw;
        }
        .carousel-control-next {
          right: 0vw;
        }
      }
    }
    .--bg-3 {
      width: 100%;
      position: relative;
      padding: 50px 0 100px 0;
      &::before {
        content: "";
        position: absolute;
        top: -35vw;
        bottom: 0;
        right: 0;
        left: 0;
        background: url("../images/mobile/licenses/Licenses_BG-03.png") bottom
          center;
        background-size: 110vw auto;
        background-repeat: no-repeat;
        background-attachment: scroll;
        transition: background 0.3s;
        z-index: -1;
      }
      .col-md-7,
      .col {
        flex: 0 0 100%;
        width: 100%;
      }
      h1 {
        &.--title {
          font-size: 1.5em;
        }
      }
      .carousel {
        .carousel-control-next,
        .carousel-control-prev {
          width: 7vw;
          &:hover {
            width: 7vw;
          }
        }
        .carousel-inner,
        .carousel-item {
          height: 85vw;
        }
        img {
          width: 50vw;
        }
        .--caption {
          font-size: 0.875em;
          line-height: 1.6;
        }
      }
      .--text-container {
        .--desc {
          font-size: 0.875em;
          line-height: 1.6;
          br {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    // background: url("../images/mobile/licenses/Licenses_BG-01.png") top -30vw center;
    // background-size: 160vw auto;
    // background-repeat: no-repeat, no-repeat;
    // background-attachment: scroll;
    // transition: background 0.3s;
    .licenses-bg {
      background: url("../images/licensesandregistrations/Licenses_BG-02.png")
        top 0 center;
      background-repeat: no-repeat;
      background-size: 185vw 167vw !important;
      transition: background 0.3s;
      height: 169vw;
    }
    .--bg-1 {
      min-height: 110vw;
    }
    .--bg-2 {
      //   min-height: 137vw;
      .carousel {
        .carousel-control-next {
          width: 9vw;
          right: 6vw;
        }
        .carousel-control-prev {
          width: 9vw;
          left: 6vw;
        }
        .carousel-inner {
          height: 90vw;
        }
      }
    }
  }
  @media (max-width: 450px) {
    // background: url("../images/mobile/licenses/Licenses_BG-01.png") top -50vw center;
    background-size: 225vw auto;
    background-repeat: no-repeat, no-repeat;
    background-attachment: scroll;
    transition: background 0.3s;
    .--bg-1 {
      min-height: 145vw;
      padding: 160px 0 100px 0;
    }
    .--bg-2 {
      //   min-height: 141vw;
    }
  }
  @media (max-width: 400px) {
    background-size: 155vw auto, 225vw auto;
    .--bg-2 {
      position: relative;
      .carousel {
        .carousel-inner {
          height: auto;
        }
        .--caption {
          br {
            display: none;
          }
        }
      }
    }
    .--bg-3 {
      h1 {
        &.--title {
          line-height: initial;
        }
      }
    }
    .license-shape {
      top: 0;
    }
  }
}
