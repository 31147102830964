/*
*
|  Page Name       : teamleaders.scss
|  Description     : scss for team leaders page use
|  Created by      : May Altamerano
|  Date Created    : July 1 2021
|  Last Update by  : May Altamerano
|  Last update     : July 1 2021
*
*/
.teamLeaderBg {
  background: #9092c6;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
.secondPosition {
  position: absolute;
  right: 0;
  margin-top: 1vw;
  margin-right: 1vw;
  font-size: 8pt;
  font-family: "gill sans", sans-serif;
  letter-spacing: 0.1vw;
  font-size: 0.7vw;
}
.teamLeaderName {
  margin-top: 5vw;
  font-size: 1vw;
  margin-left: 2vw;
  font-family: "gill sans", sans-serif;
  letter-spacing: 0.1vw;
  white-space: break-spaces;
}
.teamHr {
  width: 5vw;
  height: 3px;
  margin-left: 2vw;
  opacity: 1;
}
.teamLeaderPosition {
  width: 50%;
  font-size: 1vw;
  margin-left: 2vw;
  font-family: "gill sans medium", sans-serif;
  letter-spacing: 0.1vw;
  white-space: break-spaces;
}

@media (min-width: 993px) {
  .bottomAngle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 10vw solid #525daa;
    border-left: 22vw solid transparent;
  }
  .full-bio {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin-bottom: 2vw;
    margin-right: 2vw;
    font-family: "gill sans", sans-serif;
    letter-spacing: 0.2vw;
    font-size: 0.8vw;
  }
  .teamLeaderBox:hover {
    .teamLeaderBg {
      background: #676ab1;
      .bottomAngle {
        border-bottom: 10vw solid #414da2;
        border-left: 22vw solid transparent;
      }
      .bottomAngle2 {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 5vw solid #33429b;
        border-left: 11vw solid transparent;
        margin-right: 4vw;
      }
    }
  }
}

@media (max-width: 992px) {
  .bottomAngle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: 15vw solid #525daa;
    border-left: 35vw solid transparent;
  }
  .full-bio {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin-bottom: 3vw;
    margin-right: 2vw;
    font-size: 8pt;
    font-family: "gill sans medium", sans-serif;
    letter-spacing: 0.2vw;
  }
}
.--team-leaders {
  min-height: 100vh;
  width: 100%;
  // background: url("../images/teamleaders/Leaders_BG-01.png") top center;
  background-color: white;
  transition: background 0.3s;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 100vw auto;
  .--bg-1 {
    // background: url("../images/teamleaders/Leaders_Shapes-01.png") top 13vw right 0;
    // min-height: 58vw;
    background-repeat: no-repeat, no-repeat;
    background-size: 100vw 30vw, 125vw auto;
    transition: background 0.3s;
    background-attachment: scroll;
    padding: 10vw 0 5vw 0;
    .--shape-left {
      transition: background 0.3s;
      left: -500px;
      width: 37vw;
      position: absolute;
      top: 3vw;
    }
    .--shape-right-1 {
      transition: background 0.3s;
      right: -10vw;
      width: 37vw;
      position: absolute;
      top: 20vw;
    }
    .--max {
      max-width: 90%;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.5vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 4.7vw;
      font-size: 2.3vw;
      color: #283a97;
      margin: 5vw 0 1.5vw 0;
      text-align: center;
    }
    .--desc {
      font-family: "gill sans medium", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 13vw;
      font-size: 1vw;
      color: #283a97;
      line-height: 1.7;
      text-align: center;
    }
    .card {
      width: 94%;
      margin: 0 auto 5vw auto;
      height: auto;
      border-radius: 0 !important;
      background-color: transparent !important;
      cursor: pointer;
      height: 20vw;
      overflow: hidden;
      border: 0 !important;
      position: relative;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      img {
        border-radius: 0 !important;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:hover {
        .card-img-overlay {
          border-radius: 0 !important;
          padding: 0 !important;
          opacity: 1;
        }
      }
      .card-img-overlay {
        position: absolute;
        border-radius: 0 !important;
        padding: 0 !important;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.5s ease;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: 0 0;
        }
      }
    }
  }

  .--bg-2 {
    // background: url("../images/teamleaders/Shape.png") top -17vw center;
    background-repeat: no-repeat;
    // background-size: 100vw auto;
    transition: background 0.3s;
    background-attachment: scroll;
    padding: 12vw 0 0 0;
    min-height: 50vw;
    .--shape-right {
      transition: background 0.3s;
      right: 0;
      width: 37vw;
      position: absolute;
      top: -10vw;
    }
    .--max {
      max-width: 75%;
      min-height: 55vw;
      margin-top: 14.2vw;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      object-position: 0 0;
    }
    .--title {
      font-family: "gill sans", sans-serif !important;
      letter-spacing: 0.3vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 4.7vw;
      font-size: 1.7vw;
      color: #ffffff;
      margin: 15.8vw 0 2.5vw 6vw;
      text-align: left;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);

      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -35%;
        left: -57vw;

        width: 100vw;
        height: 51vw;
        background: url("../images/teamleaders/Shape.png");
        background-size: contain;
        z-index: -1;
      }
    }
    .join-image {
      z-index: 2;
    }
    .--sub-title {
      font-family: "gill sans light", sans-serif !important;
      letter-spacing: 0.3vw;
      font-weight: 600 !important;
      text-transform: uppercase;
      line-height: 1.6;
      font-size: 1.2vw;
      color: #283997;
      margin: 4vw 0 0 6vw;
      text-align: left;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
    }
    .--desc {
      font-family: "gill sans", sans-serif;
      font-weight: 500 !important;
      margin-bottom: 9.4vw;
      font-size: 1vw;
      color: #283a97;
      line-height: 1.7;
      margin: 1.3vw 0 0 6vw;
      text-align: left;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
    }
    .--btn-send-resume {
      position: relative;
      background: url("../images/teamleaders/Leaders_Button-01.png") center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 4vw;
      width: 15vw;
      margin: 2.5vw 0 0 6vw;
      border: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      padding: 0 !important;
      transform: scale(0) !important;
      transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
      &.is-reveal {
        transform: scale(1) !important;
        transition-delay: 0.3s;
      }
    }
  }

  @media (min-width: 1024px) {
    .--bg-1 {
      .--shape-left {
        transition: background 0.3s;
        left: -15vw;
        width: 20vw;
        position: absolute;
        top: 13vw;
      }
      .--shape-right-1 {
        transition: background 0.3s;
        right: -20vw;
        width: 37vw;
        position: absolute;
        top: 30vw;
      }

      img {
        width: 30vw;
      }
    }
    .--bg-2 {
      .--shape-right {
        transition: background 0.3s;
        right: -15vw;
        width: 37vw;
        position: absolute;
        top: 7vw;
      }
      img {
        width: 25vw;
      }
    }
  }

  @media (max-width: 992px) {
    background: url("../images/teamleaders/Leaders_BG-01.png") top center;
    transition: background 0.3s;
    background-repeat: no-repeat;
    margin-top: 0;
    background-size: auto 100% !important;
    .--shape-right-1 {
      display: none;
    }
    .--bg-1 {
      padding: 100px 0;
      background: url("../images/teamleaders/Leaders_BG-02.png") top 65vw center,
        url("../images/teamleaders/Leaders_Shapes-01.png") top 13vw right -17vw;
      min-height: 58vw;
      background-repeat: no-repeat, no-repeat;
      background-size: 100vw 65%, 125vw auto;
      transition: background 0.3s;
      background-attachment: scroll;
      .--title {
        line-height: 1.6;
        font-size: 1.5em;
        font-weight: 500 !important;
      }
      .--desc {
        font-size: 0.875em;
        line-height: 1.6;
        max-width: 90%;
        margin: 0 auto;
        br {
          display: none;
        }
      }
      .--max {
        padding: 100px 0 0 0;
      }
      .col-md-4 {
        width: 50%;
        max-width: 50%;
        &:last-child {
          width: 100%;
          max-width: 100%;
        }
      }
      .card {
        height: 40vw !important;
        width: 40vw;
        margin-bottom: 0 auto 50px auto !important;
      }
    }
    .--bg-2 {
      padding: 0;
      position: relative;
      &::before {
        position: absolute;
        content: "Join Our Team";
        font-family: "gill sans", sans-serif !important;
        letter-spacing: 0.3vw;
        font-weight: 600 !important;
        text-transform: uppercase;
        line-height: 4.7vw;
        font-size: 20px;
        color: #ffffff;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        top: 21vw;
      }
      .--title {
        font-size: 1.5em;
        margin: 0 auto 3vw auto;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
      }
      img {
        width: 50vw;
        height: auto;
        object-fit: contain;
        object-position: 0 0;
        display: block;
        margin: 200px auto 10vw auto;
      }
      .--sub-title {
        font-size: 1.375em;
        margin: 0 auto 3vw auto;
        text-align: center;
      }
      .--desc {
        font-size: 0.875em;
        margin: 0 auto;
        text-align: center;
        br {
          display: none;
        }
      }
      .--btn-send-resume {
        height: 60px;
        width: 220px;
        margin: 5vw auto 30vw auto;
        display: block;
      }
    }
  }
  @media (max-width: 669px) {
    .--bg-1 {
      background: url("../images/teamleaders/Leaders_BG-02.png") top 100vw
          center,
        url("../images/teamleaders/Leaders_Shapes-01.png") top 69vw right -17vw;
      min-height: 58vw;
      background-repeat: no-repeat, no-repeat;
      background-size: 100vw 65%, 125vw 50vw;
      transition: background 0.3s;
      background-attachment: scroll;
    }
  }
  @media (max-width: 400px) {
    .--bg-1 {
      background: url("../images/teamleaders/Leaders_BG-02.png") top 145vw
          center,
        url("../images/teamleaders/Leaders_Shapes-01.png") top 13vw right -17vw;
      background-repeat: no-repeat, no-repeat;
      background-size: 100vw 50%, 125vw auto;
    }
  }
}
