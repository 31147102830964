/*
*
|  Page Name       : leader.scss
|  Description     : scss for leaders page use
|  Created by      : May Altamerano
|  Date Created    : July 6 2021
|  Last Update by  : May Altamerano
|  Last update     : July 6 2021
*
*/
.--leader {
  min-height: 100vh;
  width: 100%;
  position: relative;

  .bottom-bg {
    position: absolute;

    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-bottom: 35vw solid #283a97;
    border-right: 100vw solid transparent;
  }
  .leader-position {
    font-size: 13pt;
    line-height: 1.5em;
    font-weight: 600;
  }
  .--bg-1 {
    min-height: 50vw;
    padding: 15vw 0 0 0;
    .--max {
      max-width: 80%;
    }
    .--leader-details {
      width: 100%;
      position: relative;
      margin-bottom: 20vw;
      h1 {
        font-family: "gill sans", sans-serif !important;
        color: #283a97;
        letter-spacing: 0.5vw;
      }
      img {
        width: 63vw !important;
        margin: 0 auto;
      }
      ul {
        position: absolute;
        top: 15vw;
        right: 0vw;
        left: 0vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        list-style: none;
        z-index: 5;
        li {
          .control-prev {
            background: url("../images/leaders/Leaders_Button-01.png") center;
            transition: background 0.3s;
            background-attachment: local;
            background-repeat: no-repeat;
            background-size: contain;
            height: 5vw;
            width: 5vw;
            display: block;
            padding: 0;
            cursor: pointer;
            span {
              display: none !important;
            }
          }
          .control-next {
            background: url("../images/leaders/Leaders_Button-02.png") center;
            transition: background 0.3s;
            background-attachment: local;
            background-repeat: no-repeat;
            background-size: contain;
            height: 5vw;
            width: 5vw;
            display: block;
            padding: 0;
            cursor: pointer;
            span {
              display: none !important;
            }
          }
        }
      }
    }
    .--about-content {
      max-width: 63vw;
      margin: 6.5vw auto 5vw auto;
      display: block;
      .--btn-get-to-know {
        width: 26.7vw;
        min-width: 200px;
        display: block;
        text-decoration: none;
        font-family: "Gill Sans", "SemiBold";
        color: #283a97;
        font-size: 18pt !important;
        box-shadow: none !important;
        outline: 0 !important;
        margin: 4.5vw auto 0 auto;
        position: relative;
        border: 1px solid #283a97;
        text-align: center;
        padding-top: 3vh;
        padding-bottom: 3vh;
        z-index: 5;
      }
      .--btn-get-to-know:hover {
        border: 1px solid white;
        color: white;
        background: #283a97;
      }
    }
  }
  .leader-bg {
    // background: url("../images/leaders/Leaders_BG-01.png") top center;
    background: white;
    transition: background 0.3s;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: auto 133vw;
    position: absolute;
    top: 0;
    min-height: 128vw;
    width: 100%;
    z-index: -5;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: url("../images/leaders/Leaders_Shapes-01.png") top -9.7vw left;
      transition: background 0.3s;
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-size: 102vw auto;
      height: 100vw;
      width: 100%;
      z-index: 2;
    }
  }

  @media (max-width: 992px) {
    background-size: auto 100%;
    padding: 100px 0 0 0;
    &::before {
      background: url("../images/leaders/Leaders_Shapes-01.png") top -9.7vw left -5vw;
      transition: background 0.3s;
      background-attachment: scroll;
      background-repeat: no-repeat;
      background-size: 108vw 115vw;
      height: 165vw;
    }
    .leader-bg {
      min-height: 100%;
      background-size: cover;
      &::before {
        background-size: 102vw 178vw;
        height: 300vw;
      }
    }
    .--bg-1 {
      .--max {
        max-width: 90%;
      }
      .--leader-details {
        img {
          width: 60vw !important;
          height: 40vw;
          object-fit: cover;
        }
        ul {
          li {
            .control-next,
            .control-prev {
              height: 8vw;
              width: 8vw;
            }
          }
        }
      }
      .--about-content {
        max-width: 70vw;
        .col-md-5,
        .col-md-7 {
          flex: 0 0 100%;
          width: 100%;
        }
        .col-md-5 {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          h3 {
            margin-bottom: 5vw;
            font-size: 2.4vw !important;
            line-height: 1.6;
            margin-left: 20px !important;
          }
          hr {
            height: 70% !important;
            width: 2px !important;
            margin: 0 4vw;
          }
          h6 {
            font-size: 2.4vw !important;
            line-height: 1.2;
          }
        }
        .col-md-7 {
          p {
            font-size: 1.125em;
            line-height: 1.6;
            &:nth-child(1) {
              font-size: 0.875em;
            }
          }
          hr {
            width: 30%;
            margin: 30px 0;
          }
        }
        .--btn-get-to-know {
          height: 55px !important;
          width: 280px !important;
          min-width: 200px;
          image-rendering: -webkit-optimize-contrast;
          margin: 50px auto 10vw auto !important;
        }
      }
    }
  }
}
